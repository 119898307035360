import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null,
    isVip: false,
  },
  getters: {
    auth: (state, getters) => {
      return state.userInfo !== null && getters.coreUser !== null
    },
    coreUser: (state, getters) => {
      return state.userInfo && state.userInfo.core_user ? state.userInfo.core_user : null
    },
    isSuperAdmin: (state, getters) => {
      return getters.auth && getters.coreUser.power >= 1024
    },
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      if (userInfo && userInfo.core_user && userInfo.core_user.vip_expired_at != null) {
        state.isVip = new Date(userInfo.core_user.vip_expired_at) > new Date()
      }
    },
    deleteUserInfo(state) {
      state.userInfo = null
      state.isVip = false
    },
  },
  actions: {
  },
  modules: {
  }
})
