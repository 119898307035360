const SETTING_KEY = 'PINKKI_SETTING';

export default {
    getItem(key, def = null) {
        let res = localStorage.getItem(key)
        if (res === null) return def
        return res
    },
    setItem(key, value) {
        localStorage.setItem(key, value)
    },
    deleteItem(key) {
        localStorage.removeItem(key)
    },
    getSetting(key, def = null) {
        let setting = JSON.parse(this.getItem(SETTING_KEY, '{}'))
        return setting[key] ?? def
    },
    setSetting(key, value) {
        let setting = JSON.parse(this.getItem(SETTING_KEY, '{}'))
        setting[key] = value
        this.setItem(SETTING_KEY, JSON.stringify(setting))
    },
    getSettingOrCreate(key, value) {
        let setting = JSON.parse(this.getItem(SETTING_KEY, '{}'))
        if (setting.hasOwnProperty(key)) return setting[key]
        this.setSetting(key, value)
        return value
    },
}
